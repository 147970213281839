import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/lieky/olynth_01/" className="sel">
              {" "}
              &gt; OLYNTH<sup>®</sup>0,1%<br/>
                   nosová roztoková<br/>
                   aerodisperzia
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> 0,1%<br/>
                      nosový roztokový<br/>
                      sprej
                    </p>
                    <div className="desc">RÝCHLA ÚĽAVA OD PRÍZNAKOV NÁDCHY</div>
                  </div>
                  <div className="photo">
                    <span className="stamp ">
                      <img src="/upload/znaczek_AZ3.png" alt="AZ3" id="az3" />
                      <img src="/upload/10h_znaczek.png" alt="10h" id="h10" />
                    </span>
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_0.1_2023_Left_NEW.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <div>
                      <strong>
                        Pri nádche dochádza k dýchacím ťažkostiam spôsobeným
                        opuchom nosovej sliznice a nadmernou tvorbou hlienu.
                      </strong>
                    </div>
                    <div>
                      <strong>
                        OLYNTH<sup>®</sup> 0,1% rýchlo uvoľňuje upchatý nos a
                        vedľajšie nosové dutiny a uľahčuje uvoľnenie
                        nahromadeného hlienu. Účinok nastupuje už od 5 – 10 minút.
                      </strong>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      OLYNTH<sup>®</sup> 0,1% je liek vo forme nosového roztokového spreja určený:
                    </div>
                    <ul>
                      <li>
                        na zmiernenie prekrvenia a opuchu nosovej sliznice pri
                        akútnej, vazomotorickej a alergickej nádche
                      </li>
                      <li>
                        &nbsp;na urýchlenie uvoľnenia sekrécie pri zápale
                        prinosových dutín (paranazálna sinusitída) a pri katare
                        Eustachovej (sluchovej) trubice spojenom s nádchou
                      </li>
                    </ul>
                    <div>&nbsp;</div>
                    <div>
                      <strong>LIEČIVO</strong>: xylometazolínium-chlorid
                      stimuluje alfa-adrenergné receptory na povrchu hladkých
                      svalových buniek v cievnej stene. V dôsledku toho dochádza
                      v mieste aplikácie ku stiahnutiu ciev, zníženiu opuchu
                      nosovej sliznice a uľahčeniu dýchania<sup>1</sup>.
                    </div>
                    <div>&nbsp;</div>
                    <div>Nástup účinku sa zvyčajne dostaví za 5-10 minút.</div>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Dávkovanie a spôsob podávania</strong>
                    </div>
                    <div>
                      Liek je určený na liečbu dospelých a detí od 6 rokov.
                    </div>
                    <div>
                      Aplikuje sa podľa potreby, najviac však 3x denne 1 dávka
                      do každej nosovej dierky.
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Upozornenie</strong>
                    </div>
                    <div>
                      <span>
                        Liek OLYNTH<sup>®</sup> 0,1% sa nemá používať dlhšie ako
                        7 dní, pokiaľ lekár neurčí inak. Aplikácia sa môže
                        opakovať iba po tom, čo liečba bola na niekoľko dní
                        prerušená.
                      </span>
                    </div>
                    <div>
                      <span>
                        Z hygienických dôvodov sa OLYNTH<sup>®</sup> 0,1% nemá
                        po otvorení používať dlhšie ako 24 týždňov.
                      </span>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                  <div>
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <sup>1 </sup>Eskiizmir G, Hirçin Z, Ozyurt B, et al.: A
                      comparative analysis of the decongestive effect of
                      oxymetazoline and xylometazoline in healthy subjects. Eur
                      J Clin Pharmacol. 2011 Jan;67(1):19-23.
                    </span>
                  </div>
                  <div>&nbsp;</div>
                  <p>
                    <a
                      className="btn_link"
                      href="http://www.sukl.sk/buxus/generate_page.php?page_id=386&lie_id=76962"
                      target="_blank"
                    >
                      PIL - Písomná informácia pre používateľa (link na
                      oficiálne webové stránky ŠÚKL)
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/deti/imunita_dietata_dbajte_a_posilnujte/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/iStock_63844397_LARGE.png)"}} />
                    </div>
                    <div className="c2">
                      Imunita dieťaťa – dbajte a posilňujte{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/domowe_sposoby_na_walke_przeziębieniem.png)"}} />
                    </div>
                    <div className="c2">
                      Domáce spôsoby boja s prechladnutím{" "}
                    </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/lieky/olynth_01/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
